<template>
	<div
		style="color: white"
	>
		{{ this.$route.params }}
	</div>
</template>
<script>
	export default {
		name: 'apple_return'
		, data: function(){
			return {

			}
		}
		, created() {
			console.log('in apple_return', this.$route.params)
			//document.location.href = `intent://callback${new URLSearchParams(this.$route.params).toString()}?#Intent;package=com.coinvest.mafinft;scheme=signinwithapple;end`

		}
	}
</script>